import React from 'react'

import { title } from './index'

import DuplicatedModule from '../DuplicatedModule'

const Duplicated = ({ pageContext: { id }, ...props }) => {
  return (
    <DuplicatedModule title={title[id]} {...props} />
  )
}

export default Duplicated
