import React, { createElement, useState } from 'react'
// import set from 'lodash/set';
import { navigate } from 'gatsby';
import { Formik, Form } from 'formik';
import { FaExclamationCircle } from 'react-icons/fa';
import * as yup from 'yup'
import useFetch from 'use-http'

import Box from 'components/Box'
import Flex from 'components/Flex'
import Input from 'components/Input'
import Label from 'components/Label'
import Board from 'components/Board'
import Text from 'components/Text'
import AreaDropdown from 'components/AreaDropdown'
import ContentLayout from 'components/ContentLayout'
import Button from 'components/Button'
import { responsive } from 'components/ThemeProvider/theme';

import useResponsive from 'contexts/mediaQuery/useResponsive'

import Captcha from '../Captcha';
import captchaShape from '../Captcha/shape';
import withNotice from '../../hoc/withNotice';

const inputTitle = [
  // {
  //   title: '您是?',
  //   options: [
  //     { title: '聲請人', id: 1 }, { title: '對造人', id: 2 }
  //   ],
  //   type: 'radio',
  //   name: 'mantype.',
  // },
  [
    {
      label: '調解委員會',
      name: 'acceptorgid',
      Comp: AreaDropdown.Field,
    },
    {
      label: '通知書編號',
      name: 'adviceno',
      placeholder: '例：A1102100006',
    },
  ],
  [
    {
      label: '聲請人或對造人身分證字號',
      name: 'national_number',
    },
    {
      label: '聯絡電話或手機',
      name: 'phone',
    },
    {
      label: 'E-mail',
      name: 'email',
      type: 'email',
    },
  ],
]

export const title = {
  proof: '聲請調解不成立證明',
  scoring: '聲請閱卷'
}

const notice = {
  proof: '為保護個人隱私資訊，限調解事件兩造當事人，方得聲請',
  scoring: '限調解事件兩造當事人聲請',
}

const claimNotice = {
  proof: '因新舊系統更替，本系統僅供110年1月1日起聲請調解之案件，線上聲請調解不成立證明書，其餘案件如需聲請調解不成立證明書，請逕行電洽各調解會承辦人員聲請，謝謝。',
  scoring: '因新舊系統更替，本系統僅供110年1月1日起聲請調解之案件，線上聲請閱卷服務，其餘案件如需聲請閱卷服務，請逕行電洽各調解會承辦人員聲請，謝謝。',
}

const claimType = {
  proof: 1,
  scoring: 2,
}

const validationSchema = yup.object().shape(inputTitle.reduce((all, group) => {
  group.forEach(field => {
    all[field.name] = yup.string().required()
    if (field.name === 'adviceno') all[field.name] = all[field.name].length(11)
    if (field.type === 'email') all[field.name] = all[field.name].email()
  })
  return all
}, { captcha: captchaShape }))

const Claim = ({ pageContext: { id }, ...props }) => {
  const { isTablet } = useResponsive()
  const { post } = useFetch('claim')
  const [formErr, setFormError] = useState()
  return (
    <ContentLayout title={title[id]} {...props}>
      <Board>
        <Flex py="0.5em" px="1.5em" bg="lightPurple" alignItems="center">
          <FaExclamationCircle size="1.25em" />
          <Box ml="0.375em">{notice[id]}</Box>
        </Flex>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ applytype: claimType[id] }}
          onSubmit={async (values, { setSubmitting, setFieldError, setFieldValue }) => {
            const res = await post(values)
            // console.log(res)
            if (res) {
              if (res['captcha.input']) {
                setFieldError('captcha.uuid', 'Invalid')
              }
              if (res.claimno) {
                navigate(`/claim/${id}/thanks?claimno=${res.claimno}`)
              }
              if (res.error) {
                if (res.error === '重複聲請') {
                  navigate(`/claim/${id}/duplicated`)
                } else {
                  setFormError(res.error)
                }
                setFieldError('captcha.uuid', 'Refresh')
              }
            }
            setSubmitting(false)
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <Flex flexDirection={responsive('column', null, 'row')} position="relative" mt="2em">
                {inputTitle.map((data, index) => (
                  <Box width={1} pl={!isTablet && (index % 2 ? '2em' : 0)} pr={!isTablet && (index % 2 ? 0 : '2em')} key={index}>
                    {data.map(({ options, Comp = Input.Field, label, title, type, name, placeholder }, i) => (
                      <Flex alignItems="center" key={i}>
                        {title && <Label>{title}</Label>}
                        <Comp py="0.75em" name={name} options={options} label={label} type={type} placeholder={placeholder} />
                      </Flex>
                    ))}
                  </Box>
                ))}
                {!isTablet && <Box position="absolute" top="0" bottom="0" left="50%" transform="translateX(-50%)" width="2px" bg="purple" />}
              </Flex>
              <Captcha mt="3em" />
              <Box textAlign="center" mt="2.875em">
                <Button type="submit" disabled={isSubmitting}>{`送出${title[id]}`}</Button>
                {formErr && <Text mt="0.5em" color="danger">{formErr}</Text>}
              </Box>
            </Form>
          )}
        </Formik>
      </Board>
    </ContentLayout>
  )
}

export default props => createElement(withNotice(claimNotice[props.pageContext.id])(Claim), props)
